<template>
  <div>
    <p>
    <u>Release Date</u>
    <br>
    • The Power of the Nexus Force release, including the changes below, is expected to release Tuesday, August 9, 2011.<br>
    <br>
    </p>
    <p align="center">
      <!-- picFFA5459C57FCC3D73B5E84462EAF27AE.jpg -->
      <img src="@/assets/news-network/power-of-the-nexus-force.jpg">
    <br></p>
    <br>
    <u>Spider Queen</u><br>
    • New players now have a quest line in Avant Gardens that ends with a fight on the Block Yard Property fighting the Spider Queen.<br>
    • Current players will be able to experience this early-to-mid-level battle by going to the launch pad in the Spider Cave in Avant Gardens.<br>
    • All players can experience the Spider Cave, knee deep with Spiderlings.<br>
    <br>
    <u>Rocket Contest Winner</u>
    <br>
    • The January Rocket Contest Winner has been added to vendor Ace Warprider in Nexus Tower. <br>
    <br>
    <u>LEGO Club Car and Rocket</u>
    <br>
    • A new LEGO Club themed rocket and car will be available for purchase from the vendor in the LEGO Club zone.<br>
    <br>
    <u>Achievements</u>
    <br>
    • 75+ new achievements have been added throughout LEGO Universe.<br>
    <br>
    <u>Missions</u>
    <br>
    • We have added 15+ new daily missions to Avant Gardens and 1 to Nimbus Station.<br>
    • We have added 20+ new missions to the Venture Explorer, Avant Gardens &amp; Nimbus Station.<br>
    <br>
    <u>Join the Nexus Force Path</u>
    <br>
    • The path to join the Nexus Force and join a Faction has been changed for new minifigures. <br>
    • Existing minifigures who have landed in Avant Gardens or Nimbus Plaza and not joined a Faction, will find all of the Avant Gardens quests completed for them and the rewards sent to their in-game mail box. They will be ready to join a Faction in Nimbus Station.<br>
    • For existing minifigures who are already part of a Faction, the new missions will be completed for them and the rewards sent to their in-game mailbox.<br>
    <br>
    <u>Venture Explorer and Avant Gardens</u>
    <br>
    • There have been several changes to the mission flow in the Venture Explorer and Avant Gardens for new minifigures.<br>
    • A new Ninjago emissary, Rocco Sirocco has a mission teaching players how to use their mailbox. As a reward, he shows off the power of Spinjitzu!<br>
    • The Venture Explorer now features several new Classic Rockets in a mix of colors (with achievements for getting them all). Existing players can collect them in the Return to Venture Explorer instance or purchase them in Avant Gardens. The Pod and Steampunk rocket modules now drop when smashing Stromling Mechs in Avant Gardens.<br>
    • New tutorial voiceovers guide the player in the early stages.<br>
    • Players logging in to a map that has been revised will be reset to the nearest respawn point.<br>
    <br>
    <u>Crux Prime</u>
    <br>
    • Good news! The Nexus Force initiative in Crux Prime is succeeding. Fewer Maelstrom monsters will now spawn as a result.<br>
    • The amount of coins and tokens dropped by monsters in Crux Prime have been increased.<br>
    • Named Maelstrom monsters will now spawn a bit more often to inspire their troops.<br>
    • Daily missions to smash named Maelstrom monsters have been replaced by Achievements.<br>
    <br>
    <u>Leveling and Progression</u>
    <br>
    • A new Leveling system based on player U-Score has been added to the game.<br>
    • Existing players will have their old U-Score converted the first time they log into their minifigures.<br>
    • Any rewards granted by levels will be placed in the minifigure's backpack or mailed to them if they do not have the room.<br>
    • Players will see their own level in the top corner of the screen, including a new bar to track how much more U-Score they need to go up a level.<br>
    • All existing achievements and missions have had their U-Score rebalanced.<br>
    <br>
    <u>Items</u>
     <br>
    • Paradox Shinobi Faction Gear is now available. Rank 1 and 2 are available from the Paradox vendor in Nimbus Plaza. Rank 3 is available from the Paradox vendor in Nexus Tower.<br>
    • Assembly Inventor Faction Gear is now available. Rank 1 and 2 are available from the Assembly vendor in Nimbus Plaza. Rank 3 is available from the Assembly vendor in Nexus Tower.<br>
    • Sentinel Space Ranger Faction Gear is now available. Rank 1 and 2 are available from the Sentinel vendor in Nimbus Plaza. Rank 3 is available from the Sentinel vendor in Nexus Tower.<br>
    • Venture League Adventurer Faction Gear is now available. Rank 1 and 2 are available from the Venture League vendor in Nimbus Plaza. Rank 3 is available from the Venture League vendor in Nexus Tower.<br>
    • Capes are now available. Capes are worn in the shoulder/neck slot and are available in a variety of colors.<br>
    • Valiant Capes for all Rank 3 Faction Kits are also available. These capes count towards the Faction Kit set bonuses.<br>
    • Some capes are available as rewards for reaching certain levels, including the Nexus Force Cape.<br>
    • Several items have had their stats changed and as a result have had their price and/or rarity changed. Here are a few examples:<br>
    • The Firecracker now deals 3 damage instead of 1.<br>
    • The Dragon Helm Mk 1 has been changed to 2 Armor, 1 Imagination.<br>
    • The Faction Token cost of Rank 2 gear pieces has been lowered from 80 tokens to 60 tokens.<br>
    <br>
    <u>Fixes</u>
     <br>
    • Miscellaneous tweaks and bug fixes have been made, including: <br>
    • The amount of coins dropped when a player is smashed has been reduced by 50%.<br>
    • Performance has improved in the Venture Explorer, Avant Gardens and Nimbus Station (higher framerate and less hitching).<br>
    • Dragging a brick or model from your Backpack will cause your minifigure to equip his Thinking Hat automatically.<br>
    • Property load times have been improved.<br>
    • Using consumable items from slot 5 on the Action Bar will now take items from the smallest stack first instead of the first stack it finds.<br>
    • When the last item of a stack is consumed, its icon will disappear from Slot 5 on the Action Bar.<br>
    • Music should now play for all players on a Property, instead of just the owner.<br>
    • Players can no longer delete a mail that has an undeletable attachment (such as a mission item); they must first remove the item into their backpack and then can delete the mail.<br>
    • Daily missions to visit Properties in Avant Gardens and Nimbus Station now include Avant Grove and Nimbus Isle.<br>
    • Players can now complete the final Nexus Tower Achiever Achievement.<br>
    • Coin and Faction Token drops have been decreased in Gnarled Forest.<br>
    • Changes have been made to limit the abuse of sending invites to become Friends Team up, and Trade. <br>
    • Clicking on the mission icon for a completed mission now shows the orange “return to mission giver” pop-up.<br>
    • Nimbus Station features new signage to help guide players.<br>
    • Treehouse and Space Fort reward model sets are available for sale in the Avant Garden picnic area.<br>
    • If you try to whisper or team chat with certain players, a message will appear that they cannot receive your chat because they are playing on Free to Play accounts.<br>
    • If you try to Best Friend certain players, you will get a message that they cannot because they are playing on Free to Play accounts.<br>
    • If you try to trade with certain players, you will get a message that they are unavailable for trade.<br>
    <br>
    <u>Known Issues</u>
    <br>
    • New top tiers for the Nexus Force Commendation and LEGO Universe Commendation achievements will not be complete-able by all players until future content comes on-line.<br>
    • Some icons may not appear immediately if “Download During Play” is selected.<br>
    • Loading each world will take longer the first time after this update is downloaded, but will be quicker than before on subsequent times.
  </div>
</template>

<script>
export default {}
</script>